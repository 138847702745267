import axios from 'axios'
import Cookie from 'js-cookie'

const xsrfHeaderName = 'Ubi-Front-Authorization'
axios.defaults.timeout = 500000
axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = xsrfHeaderName
axios.defaults.xsrfCookieName = xsrfHeaderName

// http method
const METHOD = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put'
}

/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function request(url, method, params, config = {}) {
  const withCredentials = Object.prototype.hasOwnProperty.call(config, 'withCredentials') ? config.withCredentials : true // 默认设置为 true
  axios.defaults.withCredentials = withCredentials

  switch (method) {
    case METHOD.GET:
      return axios.get(url, {params, ...config})
    case METHOD.POST:
      return axios.post(url, params, config)
    case METHOD.PUT:
      return axios.put(url, params, config)
    case METHOD.DELETE:
      return axios.delete(url, {params, ...config})
    default:
      return axios.get(url, {params, ...config})
  }
}

/**
 * 设置认证信息
 * @param auth {Object}
 * @param authType {AUTH_TYPE} 认证类型，默认：{AUTH_TYPE.BEARER}
 */
function setAuthorization(auth) {
  Cookie.set(xsrfHeaderName, 'Bearer ' + auth.token, {
    expires: auth.expireAt,
    sameSite: 'Strict'
  })
}

/**
 * 移出认证信息
 * @param authType {AUTH_TYPE} 认证类型
 */
function removeAuthorization() {
  Cookie.remove(xsrfHeaderName)
}

/**
 * 检查认证信息
 * @param authType
 * @returns {boolean}
 */
function checkAuthorization() {
  if (Cookie.get(xsrfHeaderName)) {
    return true
  }
  return false
}

/**
 * 加载 axios 拦截器
 * @param interceptors
 * @param options
 */
function loadInterceptors(router) {
  // 加载响应拦截器
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response) {
        if (error.response.status ===  401) {
          removeAuthorization()
          router.push('/');
        }
      }
      return Promise.reject(error);
    }
  )
}

export {
  METHOD,
  request,
  setAuthorization,
  removeAuthorization,
  checkAuthorization,
  loadInterceptors
}
